import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import './style.css';
// import './App.css'
import AOS from 'aos';
import 'aos/dist/aos.css';
import Stack from '@mui/material/Stack';
import Autocomplete from '@mui/material/Autocomplete';
import { Container, Dialog, AppBar, Toolbar, List, Card, CardContent, Slide, TextField } from '@mui/material';
// import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import { LocationOn, Phone, CheckCircle, FavoriteBorder } from '@mui/icons-material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import SearchIcon from '@mui/icons-material/Search';
import Cookies from 'js-cookie';
// import { FaWhatsapp } from "react-icons/fa";
// import ReactSearchBox from "react-search-box";

// import CategoryIcon from '@mui/icons-material/Category';
// import OutlinedInput from '@mui/material/OutlinedInput';
// import BusinessIcon from '@mui/icons-material/Business';
// import HowToRegIcon from '@mui/icons-material/HowToReg';
// import PercentIcon from '@mui/icons-material/Percent';
import { jwtDecode } from "jwt-decode";
// import HomeIcon from '@mui/icons-material/Home';
import FavoriteIcon from '@mui/icons-material/Favorite';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircle from '@mui/icons-material/AccountCircle';
import Logo from '../../assets/logo/recent2.png';
import Twitter from '../../assets/qik call mobile view icons/Group.png'
import Whatsapp from '../../assets/qik call mobile view icons/Group76.png'
import Fb from '../../assets/qik call mobile view icons/Group77.png'
import Linkedin from '../../assets/qik call mobile view icons/Group78.png'
import Instagram from '../../assets/qik call mobile view icons/Group80.png'
import Telegram from '../../assets/qik call mobile view icons/telegram1.png'
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import MiscellaneousServicesIcon from '@mui/icons-material/MiscellaneousServices';
// import FeaturedVideoIcon from '@mui/icons-material/FeaturedVideo';
// import PlaylistAddCheckIcon from '@mui/icons-material/PlaylistAddCheck';
// import PersonAddIcon from '@mui/icons-material/PersonAdd';
// import LoginIcon from '@mui/icons-material/Login';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import LanguageIcon from '@mui/icons-material/Language';

import { Button, Typography, IconButton, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { FaFacebook, FaInstagram, FaYoutube, FaLinkedin, FaTelegram, FaTwitter as FaXTwitter, FaWhatsapp } from 'react-icons/fa';
import Grid2 from '@mui/material/Unstable_Grid2/Grid2';
// import { IoIosPhonePortrait } from "react-icons/io";
// import { FaFacebook, FaInstagram, FaYoutube, FaLinkedin, FaTelegram, FaWhatsapp } from 'react-icons/fa';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


function NavBar() {


  const socialLinks = [
    { name: 'Facebook', icon: <FaFacebook className='social-icon' />, url: '#' },
    { name: 'Instagram', icon: <FaInstagram className='social-icon' />, url: '#' },
    { name: 'YouTube', icon: <FaYoutube className='social-icon' />, url: '#' },
    { name: 'X', icon: <FaXTwitter className='social-icon' />, url: '#' },
    { name: 'LinkedIn', icon: <FaLinkedin className='social-icon' />, url: '#' },
    { name: 'Telegram', icon: <FaTelegram className='social-icon' />, url: '#' },
    { name: 'WhatsApp', icon: <FaWhatsapp className='social-icon' />, url: '#' },
  ];
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 725);
  const [language, setLanguage] = React.useState('EN');
  const [getData, setGetData] = useState('')
  // const [inputText, setInputText] = useState("");


  // let inputHandler = (e) => {
  //   //convert input text to lower case
  //   var lowerCase = e.target.value.toLowerCase();
  //   setInputText(lowerCase);
  // };

  const handleChange = (event) => {
    setLanguage(event.target.value);
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 725);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: true
    });
  }, []);

  // data = [
  //   {
  //     key: "john",
  //     value: "John Doe",
  //   },
  //   {
  //     key: "jane",
  //     value: "Jane Doe",
  //   },
  //   {
  //     key: "mary",
  //     value: "Mary Phillips",
  //   },
  //   {
  //     key: "robert",
  //     value: "Robert",
  //   },
  //   {
  //     key: "karius",
  //     value: "Karius",
  //   },
  // ];



  const handleFreelancer = () => {
    navigate('/freelancer');
  };

  const handleHome = () => {
    navigate('/');
  };

  const handlebusiness = () => {
    navigate('/registerbusiness');
  };

  const handlebusinessdetails = () => {
    navigate('/businessdetails');
  };

  const handleFreelist = () => {
    navigate('/free-listing');
  };

  const handleFreelancerdetails = () => {
    navigate('/freelancerdetails');
  };

  const handleWishlist = () => {
    navigate('/wishlist');
  };

  const handleUserprofile = () => {
    navigate('/userprofile');
  };

  const handleDetails = () => {
    navigate('/usersdetails/:token');
  };

  const handlesignin = () => {
    navigate('/signin');
  };

  const handleNotify = () => {
    navigate('/notifications');
  };

  const handleAdvertise = () => {
    navigate('/advertise');
  };

  const handleOffer = () => {
    navigate('/today-offer');
  };

  const handleNavigate = (category) => {
    navigate(`/categorieslist/${category}`)
  }

  const handleBizsales = () => {
    navigate(`/bizsales`)
  }

  useEffect(() => {
    GetCategory()
  }, [])

  const GetCategory = () => {
    try {
      const requestOptions = {
        method: "GET",
        redirect: "follow"
      };

      fetch("/api/categorylist", requestOptions)
        .then(async (response) => {
          if (response.status === 200 || response.status === 400) {
            return { status_code: response.status, data: await response.json() };
          } else {
            return { status_code: response.status, data: { msg: 'Unexpected Error' } };
          }
        })
        .then((result) => {
          setGetData(result.data.data)
          console.log(result.data.data, "dinosaur")
        })
        .catch(error => console.log('error', error));
    } catch (error) {
      console.error("Request Error:", error);
    }

  }

  const [search, setSearch] = useState('');
  const [searchdata, setSearchdata] = useState('');

  console.log(searchdata, "112122122");
  console.log(search, "lllllllllllll");

  const getSearch = (e) => {
    e.preventDefault(); // Prevent the form from submitting the traditional way

    // Ensure the search value is not empty
    if (!search) {
      console.log("Please enter a search term.");
      return;
    }

    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const payload = {};

      if (isNaN(search)) {
        // If search is not a number, assume it's either a servicename or city
        payload.servicename = search;
        payload.city = search;
      } else {
        // If search is a number, assume it's a phone number
        payload.number = search;
      }

      const raw = JSON.stringify(payload);

      console.log("Request Payload:", raw);

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch("/api/search", requestOptions)
        .then(async (response) => {
          if (response.status === 200 || response.status === 400) {
            const result = await response.json();
            setSearchdata(result.data);
            console.log("API Response:", result.data);
            return { status_code: response.status, data: result };
          } else {
            console.log("Unexpected Status Code:", response.status);
            return { status_code: response.status, data: { msg: 'Unexpected Error' } };
          }
        })
        .then((result) => {
          if (result.status_code === 200) {
            console.log("Searched Data:", result.data);
            // Handle the result.data as needed in your UI
          } else if (result.status_code === 400) {
            console.error("Search Error:", result.data.msg);
            // Handle the error as needed in your UI
          }
        })
        .catch((error) => {
          console.error("Fetch Error:", error);
        });

    } catch (error) {
      console.error("Request Error:", error);
    }
  };

  const [searchPin, setSearchPin] = useState([])

  const SearchbyPin = () => {
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({
        "pincode": text
      });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch("/api/search_pincode", requestOptions)
        .then(async (response) => {
          if (response.status === 200 || response.status === 400) {
            const result = await response.json();
            setSearchPin(result.data);
            console.log("API Response:", result.data);
            return { status_code: response.status, data: result };
          } else {
            console.log("Unexpected Status Code:", response.status);
            return { status_code: response.status, data: { msg: 'Unexpected Error' } };
          }
        })
        .then((result) => {
          if (result.status_code === 200) {
            console.log("Searched Data:", result.data);
            // Handle the result.data as needed in your UI
          } else if (result.status_code === 400) {
            console.error("Search Error:", result.data.msg);
            // Handle the error as needed in your UI
          }
        })
        .catch((error) => {
          console.error("Fetch Error:", error);
        });
    } catch (error) {
      console.error("Fetch Error:", error);

    }
  }

  const [pincode, setPincode] = useState([]);
  const [text, setText] = useState('');

  console.log(pincode, "Pincode")

  const pincodeDetails = () => {
    const pincodeData = text;
    console.log(pincodeData, "pincodeData");

    const requestOptions = {
      method: "GET",
      redirect: "follow"
    };

    fetch(`https://api.postalpincode.in/pincode/${pincodeData}`, requestOptions)
      .then(async (response) => {
        const data = await response.json();
        if (response.status === 200 || response.status === 400) {
          return { status_code: response.status, data: data };
        } else {
          return { status_code: response.status, data: { msg: 'Unexpected Error' } };
        }
      })
      .then((result) => {
        if (result.status_code === 200 && result.data[0].Status === 'Success') {
          setPincode(result.data[0].PostOffice || []);
          console.log(result.data[0].PostOffice, "fetched data");
        } else {
          setPincode([]); // Clear options if the status is not successful
        }
      })
      .catch(error => console.log('error', error));
  };

  const [selectedArea, setSelectedArea] = useState(null);

  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    setFilteredData(searchPin); // Initially display all data
  }, [searchPin]);

  const handleAreaChange = (event, value) => {

    const selected = pincode.find(option => option.Name === value);
    setSelectedArea(selected);


    if (searchPin.length === 0) {
      console.log('searchPin is empty');
      setFilteredData([]);
      return;
    }

    if (value) {
      const valueString = String(value).trim().toLowerCase();

      // Use filter method to find matching data
      const filtered = searchPin.filter(item => {
        const areaString = String(item.area).trim().toLowerCase();
        console.log(`Comparing "${areaString}" with "${valueString}"`);
        return areaString === valueString;
      });

      console.log('Filtered data:', filtered);
      setFilteredData(filtered);
    } else {
      // If value is null or empty, reset to show all data
      setFilteredData(searchPin);
    }
  };

  const handleCategoryDetails = (id) => {

    navigate(`/categories-details/${id}`);
  }



  const location = useLocation();

  const tokenFromUrl = new URLSearchParams(location.search).get('token'); // Assuming the token is a query parameter in the URL

  let token = Cookies.get('token') ? Cookies.get('token') : undefined;
  const [decode, SetDecode] = useState(token ? jwtDecode(token) : undefined);
  console.log(decode, "killer")

  // useEffect(() => {
  //   if (!decode && !tokenFromUrl) {
  //     handlesignin();
  //   } else if (decode) {
  //     handleUserprofile();
  //   } else if (tokenFromUrl) {
  //     handleDetails();
  //   }
  // }, [decode, tokenFromUrl]);

  const [searchopen, setSearchOpen] = React.useState(false);
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setSearchOpen(true);
  };

  const handleClose = () => {
    setSearchOpen(false);
  };

  const handleCodeOpen = () => {
    pincodeDetails()
    SearchbyPin()
    setOpen(true);
  };

  const handleCodeClose = () => {
    setOpen(false);
  };

  return (
    <>

      {isMobile ? (

        <>



          <Grid xs={12} sm={12} >
            <nav >
              <Grid item xs={12} sm="auto" container direction={{ xs: 'column', sm: 'row' }} justifyContent="center" alignItems="center" mt={1} >
                <Grid sx={{ textAlign: 'center' }}>
                  <div>
                    <img className="" src={Logo} onClick={handleHome} alt="logo" width={"100px"} height={"100px"} />
                    <Typography className="mb-2" id="titletext-mob" sx={{ color: "red", fontSize: "25px", fontFamily: "Anton, sans-serif", fontWeight: "bold", fontStyle: "italic" }}>Qik Call</Typography>
                  </div>
                </Grid>
                {/* <Grid container xs={12} direction='row' justifyContent="space-evenly" alignItems="center" mt={1} >
                  <Grid container className='mb-1' item xs={6} sm="auto" justifyContent="space-around" alignItems="center">
                    <Button id='Topbutton-mob' > Business Login/signup</Button>
                  </Grid>
                  <Grid container className='mb-1' item xs={6} sm="auto" justifyContent="space-around" alignItems="center">
                    <Button id='Topbutton-mob' >Always Free Ads(T&C)</Button>
                  </Grid>
                </Grid> */}
              </Grid>

              <Grid container xs={12} mt={0.5} direction="row" justifyContent="center" alignItems="center">
                <Grid item xs={6} className='mb-1' sm="auto" container direction="row" justifyContent="center" alignItems="center" >
                  <Select
                    value={language}
                    onChange={handleChange}
                    sx={{
                      backgroundColor: "#2d2859",
                      width: "auto",
                      height: "25px",
                      borderRadius: "20px",
                      color: "white",
                      fontSize: "8px",
                      marginLeft: "3px",
                      '.MuiSelect-icon': {
                        color: "white"
                      },
                    }}
                  // inputProps={{ 'aria-label': 'Without label' }}
                  >
                    <MenuItem value="EN">
                      <LanguageIcon id="selct-text-mob" sx={{ fontSize: "15px", marginRight: "5px", fontFamily: "Anton, sans-serif", fontWeight: "bold", fontStyle: "italic" }} /> EN
                    </MenuItem>
                    <MenuItem value="ES">
                      <LanguageIcon sx={{ fontSize: "15px", marginRight: "1px", fontFamily: "Anton, sans-serif", fontWeight: "bold", fontStyle: "italic" }} /> ES
                    </MenuItem>
                    {/* Add more MenuItems here as needed */}
                  </Select>
                </Grid>
                <Grid item xs={6} className='mb-1' sm="auto" container direction="row" justifyContent="space-evenly" alignItems="center"  >
                  <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ backgroundColor: "#2D2859", height: "25px", borderRadius: "20px", width: "90px" }} >
                    <Grid >
                      <IconButton onClick={handleWishlist} edge="start" sx={{ color: "#f7f4cd" }} >
                        <FavoriteIcon sx={{ fontSize: "11px" }} />
                      </IconButton>
                      <IconButton edge="center" onClick={handleNotify} sx={{ color: "#f7f4cd" }} >
                        <NotificationsIcon sx={{ fontSize: "11px" }} />
                      </IconButton>
                      <IconButton
                        // onClick={() => {
                        //   if (!token ) {
                        //     handlesignin();
                        //   } else {
                        //     handleUserprofile();
                        //   }
                        // }}
                        edge="end"
                        sx={{ color: "#f7f4cd" }}
                      >
                        <AccountCircle sx={{ fontSize: "11px" }} />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>




              <Grid container direction="row" justifyContent="center" alignItems="center" mt={0.5} mb={2}>
                <Grid xs={12} container direction="row" justifyContent="space-evenly" alignItems="center" >
                  <Grid item xs={3} sm="auto" container direction="row" justifyContent="space-evenly" alignItems="center">
                    <Button id="mid-button-mob" onClick={handlebusiness}>Business</Button>
                  </Grid>
                  <Grid item xs={3} sm="auto" container direction="row" justifyContent="space-evenly" alignItems="center">
                    <Button id="mid-button-mob" onClick={handleAdvertise} >Advertise</Button>
                  </Grid>
                  <Grid item xs={3} sm="auto" container direction="row" justifyContent="space-evenly" alignItems="center">
                    <Button id="mid-button-mob" onClick={handleFreelist}>Freelilsting(T&C)</Button>
                  </Grid>
                  <Grid item xs={3} sm="auto" container direction="row" justifyContent="space-evenly" alignItems="center">
                    <Button id="mid-button-mob" onClick={handleNavigate}>Freelancer</Button>
                  </Grid>
                </Grid>
              </Grid>

              <Grid container direction="row" justifyContent="space-evenly" alignItems="center">
                <Grid>
                  <button onClick={handleOffer} className="button-36-mob pulse-button">
                    <span className="text">Today Offers</span>
                  </button>
                </Grid>
                <Box sx={{ width: "267px", height: "28px", border: "1px solid #000" }}  >
                  <Grid item xs={12} sm="auto" container direction="row" justifyContent="space-evenly" alignItems="center">
                    <Grid>
                      <img src={Twitter} href="#" alt="" id="social-icon-mob" />
                    </Grid>
                    <Grid>
                      <img src={Whatsapp} href="#" alt="" id="social-icon-mob" />
                    </Grid>
                    <Grid>
                      <img src={Fb} alt="" href="#" id="social-icon-mob" />
                    </Grid>
                    <Grid>
                      <img src={Linkedin} href="#" alt="" id="social-icon-mob" />
                    </Grid>
                    <Grid>
                      <img src={Instagram} href="#" alt="" id="social-icon-mob" />
                    </Grid>
                    <Grid>
                      <img src={Telegram} href="#" alt="" id="social-icon-mob" />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>

              <hr className="w-100 ezy__nav1_vFX4KuxH-separator" />
              <Grid container direction="row" justifyContent="space-around" alignItems="center" >
                <Grid xs={12} container direction="row" justifyContent="space-evenly" alignItems="center">
                  <Grid xs={2} container justifyContent="center" alignItems="center">
                    <Button sx={{ backgroundColor: "#2d2859", fontSize: "8px", width: "auto" }} variant='contained'>
                      <LocationOnIcon sx={{ fontSize: "20px", fontFamily: "Anton, sans-serif", fontWeight: "bold", fontStyle: "italic" }} />Nearby 50km
                    </Button>
                  </Grid>
                  <Grid item xs={9} container direction='row' justifyContent="flex-start" alignItems="center">
                    <Grid
                      item
                      container
                      direction="row"
                      justifyContent="center"
                      alignItems="center"
                      className="gridContainer-mob"
                    >
                      <Grid item className="dropdown-mob">
                        <Select
                          value={language}
                          onChange={handleChange}
                          className="select-mob"
                        >
                          <MenuItem value="EN">State</MenuItem>
                          <MenuItem value="ES">ES</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item className="dropdown-mob">
                        <Select
                          value={language}
                          onChange={handleChange}
                          className="select-mob"
                        >
                          <MenuItem value="EN">District</MenuItem>
                          <MenuItem value="ES">ES</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item className="dropdown-mob">
                        <Select
                          value={language}
                          onChange={handleChange}
                          className="select-mob"
                        >
                          <MenuItem value="EN">Cities</MenuItem>
                          <MenuItem value="ES">ES</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item className="dropdown-mob">
                        <Select
                          value={language}
                          onChange={handleChange}
                          className="select-mob"
                        >
                          <MenuItem value="EN">Area</MenuItem>
                          <MenuItem value="ES">ES</MenuItem>
                        </Select>
                      </Grid>
                      <Grid item xs={1}>
                        <span><SearchIcon id="input-mob" /></span>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

              </Grid>
              <Grid container direction="row" justifyContent="space-evenly" alignItems="center" mt={1.5}>
                <Grid xs={3} container direction="row" justifyContent="flex-start" alignItems="center">
                  <Select
                    value={language}
                    onChange={handleChange}
                    label="Category"
                    className="select-category-mob"
                  >
                    <MenuItem value="EN">Category</MenuItem>
                    <MenuItem value="ES">ES</MenuItem>
                    {/* Add more MenuItems here as needed */}
                  </Select>
                </Grid>
                <Grid xs={8} container direction="row" justifyContent="center" alignItems="center">
                  <Paper component="form" className="search-paper-mob">
                    <InputBase
                      className="search-input-mob"
                      placeholder="Search"
                    />
                    <IconButton type="button" className="search-button-mob" aria-label="search">
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                </Grid>
              </Grid>
            </nav>
          </Grid>
          {/*                 
                 <ul className="navbar-nav flex-row mb-2 mb-lg-0">
                 <Grid className="h-50 mt-3 me-3">
                       <a href="" className='cat'><CategoryIcon /> All Category</a>
                     </Grid>
                   <li className="nav-item me-2">
                     <button className="btn px-4 mr-1"><PersonAddIcon /> Signup</button>
                   </li>
                   <li className="nav-item me-2">
                     <button className="btn px-4"><LoginIcon />Login</button>
                   </li>
                 </ul> */}
        </>
      ) : (
        <Container maxWidth="1200px" sx={{ backgroundColor: "#f7f4cd" }}>
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
            mt={1}
          >
            <Grid sm="auto" sx={{ textAlign: 'center', position: 'relative' }}>
              {/* Triangle behind the image */}
              {/* <div
          style={{
            width: 0,
            height: 0,
            borderLeft: '75px solid transparent',
            borderRight: '75px solid transparent',
            borderBottom: '130px solid red',
            position: 'absolute',
            top: '2px', // Adjust position as needed
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 0,
            filter: 'blur(4px)', // Optional: Blur the triangle for a softer shadow effect
          }}
        /> */}

              {/* Image */}
              <div style={{ position: 'relative', zIndex: 1 }}>
                <img
                  width="150px"
                  height="150px"
                  onClick={handleHome}
                  src={Logo}
                  alt="logo"
                  style={{ position: 'relative' }}
                />
              </div>

              {/* Typography */}
              <Typography id="titletext" sx={{ fontWeight: 'bold', color: 'red', mt: 1 }}>
                Qik Call
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="column" alignItems="flex-end" mt={2}>
            <nav className="social">
              <ul>
                {socialLinks.map((link, index) => (
                  <li key={index}>
                    <a href={link.url} target="_blank" rel="noopener noreferrer">
                      {link.name} {link.icon}
                    </a>
                  </li>
                ))}
              </ul>
            </nav>
          </Grid>
          <Grid
            container
            direction='row'
            justifyContent="space-evenly"
            alignItems="center"
            mt={1}
            mb={1}
          >
            <Grid item xs={3} sm="auto" container direction="row" justifyContent="center" alignItems="center"  >
              <Select
                value={language}
                onChange={handleChange}
                sx={{
                  backgroundColor: "#C052E3",
                  borderRadius: "12px",
                  width: "120px",
                  height: "40px",
                  color: "white",
                  fontSize: "10px",
                  marginLeft: "7px",
                  border: "none", // This will remove the border
                  '.MuiSelect-icon': {
                    color: "white"
                  },
                  '.MuiOutlinedInput-notchedOutline': {
                    border: "none" // Ensures the outline is removed when focused
                  },
                }}
              // inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value="EN">
                  <LanguageIcon sx={{ fontSize: "15px", marginRight: "5px", fontFamily: "Anton, sans-serif", fontWeight: "bold", fontStyle: "italic" }} /> EN
                </MenuItem>
                <MenuItem value="ES">
                  <LanguageIcon sx={{ fontSize: "15px", marginRight: "1px", fontFamily: "Anton, sans-serif", fontWeight: "bold", fontStyle: "italic" }} /> ES
                </MenuItem>
                {/* Add more MenuItems here as needed */}
              </Select>
            </Grid>
            <Grid item xs={3} sm="auto" container direction='row' justifyContent="space-evenly" alignItems="center">
              <Button id="end-button" onClick={() => {
                if (decode && decode.user ? decode.user.role == "freelancer" : undefined) {
                  handleFreelancerdetails()
                } else {
                  handleFreelancer()
                }
              }}>Freelancer</Button>
            </Grid>

            <Grid item xs={3} sm="auto">
              <Button id="mid-button" onClick={handleAdvertise}>Advertise</Button>
            </Grid>
            {/* <Grid item xs={3} sm="auto">
              <Button id="mid-button" className=" mt-2"><HowToRegIcon /> Business Login/Signup</Button>
            </Grid> */}
            <Grid item xs={1.3} container direction='row' justifyContent="space-evenly" alignItems="center" >
              {/* <button onClick={handleOffer} className='button-36 pulse-button'>
                    <Typography className="text">Today Offer</Typography>
                  </button> */}
              <Button onClick={handleOffer} class="test-btn">Today Offer</Button>
            </Grid>

            <Grid item xs={3} sm="auto">
              <Button id="mid-button" onClick={() => {
                if (decode && decode.user ? decode.user.role == "businessman" : undefined) {
                  handlebusinessdetails()
                } else {
                  handlebusiness()
                }
              }} className=" mt-2"> Business</Button>
            </Grid>


            <Grid item xs={3} sm="auto">
              <Button id="end-button" className=" mt-2" onClick={handleFreelist}>Freelilsting (T&C)</Button>
            </Grid>
            <Grid item xs={3} mt={1} sm="auto" container direction="row" justifyContent="flex-start" alignItems="center"  >
              <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ backgroundColor: "#C052E3", borderRadius: "12px", width: "120px", height: "40px" }} >
                <Grid >
                  <IconButton onClick={handleWishlist} edge="start" sx={{ color: "#f7f4cd" }} >
                    <FavoriteIcon sx={{ fontSize: "20px" }} />
                  </IconButton>
                  <IconButton onClick={handleNotify} edge="center" sx={{ color: "#f7f4cd" }} >
                    <NotificationsIcon sx={{ fontSize: "20px" }} />
                  </IconButton>
                  <IconButton
                    onClick={() => {
                      if (!decode && !tokenFromUrl) {
                        handlesignin();
                      } else if (decode) {
                        handleUserprofile();
                      } else if (tokenFromUrl) {
                        handleDetails();
                      }
                    }}
                    edge="end"
                    sx={{ color: "#f7f4cd" }}
                  >
                    <AccountCircle sx={{ fontSize: "20px" }} />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>

            {/* <Grid item xs={3} sm="auto">
              <Button id="end-button" className=" mt-2"> Always Free Ads </Button>
            </Grid> */}
          </Grid>


          <Grid direction='row' justifyContent="space-evenly" alignItems="center">
            <nav>
              <hr className="ezy__nav1_vFX4KuxH-separator" />
              <Grid xs={12} container direction='row' justifyContent="space-evenly" alignItems="center" mb={3} >
                <Grid item xs={1.5}>
                  <Button onClick={handleBizsales} sx={{ backgroundColor: "#2d2859", borderRadius: "12px", fontFamily: "Anton, sans-serif", fontWeight: "bold", fontStyle: "italic", fontSize: "10px", width: "130px", height: "50px", '&:hover': { backgroundColor: "#2d2859" } }} variant='contained'>
                    <LocationOnIcon />Biz-Sales
                  </Button>
                </Grid>
                <Grid item xs={4.5} container direction="row" justifyContent="flex-start" alignItems="center">
                  <Paper
                    component="form"
                    onSubmit={getSearch} // Attach the getSearch function to the form's onSubmit
                    sx={{
                      p: '2px 4px',
                      display: 'flex',
                      alignItems: 'center',
                      width: "100%",
                      height: "50px",
                      fontFamily: "Anton, sans-serif",
                      fontWeight: "bold",
                      fontStyle: "italic",
                      '&:hover': { border: "2px solid #2d2859" },
                    }}
                  >
                    <InputBase
                      sx={{ ml: 1, flex: 1 }}
                      placeholder="Search by city, servicename, or number"
                      value={search}
                      onChange={(e) => {
                        setSearch(e.target.value);
                      }}
                    />
                    <IconButton type="submit" disabled={!search} onClick={handleClickOpen} sx={{ p: '10px' }} aria-label="search">
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                </Grid>

                <Grid item xs={1.1} className="h-50 me-3" container direction='row' justifyContent="center" alignItems="center">
                  <Select
                    value={language}
                    // onChange={handleChange}
                    placeholder="Category"
                    sx={{
                      backgroundColor: "#2d2859",
                      borderRadius: "12px",
                      fontSize: "13px",
                      width: "120px",
                      height: "50px",
                      color: "white",
                      fontFamily: "Anton, sans-serif", fontWeight: "bold", fontStyle: "italic",
                      border: "none", // This will remove the border
                      '.MuiSelect-icon': {
                        color: "white"
                      },
                      '.MuiOutlinedInput-notchedOutline': {
                        border: "none" // Ensures the outline is removed when focused
                      },
                    }}
                  >
                    <MenuItem value="EN">Category</MenuItem>
                    {Array.isArray(getData) && getData.map((category, index) => (
                      <MenuItem onClick={(e) => { handleNavigate(getData[index]._id) }} value={category}>{category.categoryname}</MenuItem>
                    ))}

                  </Select>
                </Grid>



                <Grid xs={3} container direction="row" justifyContent="center" alignItems="center">
                  <Paper sx={{ backgroundColor: "#2d2859" }} >
                    <Stack direction="row" alignItems="center">
                      <TextField
                        placeholder="Search by Pincode"
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                        sx={{
                          '& .MuiOutlinedInput-root': {
                            color: '#FFF',
                            '& fieldset': {
                              border: 'none',
                            },
                            '&:hover fieldset': {
                              border: 'none',
                            },
                            '&.Mui-focused fieldset': {
                              border: 'none',
                            },
                          },
                          '& .MuiInputLabel-root': {
                            color: '#FFF',
                          },
                        }}
                      />
                      <Button disabled={!text} onClick={handleCodeOpen} type="submit">
                        <SearchIcon sx={{ color: "#fff" }} />
                      </Button>
                    </Stack>

                  </Paper>
                </Grid>


                <Dialog
                  fullScreen
                  open={searchopen}
                  onClose={handleClose}
                  TransitionComponent={Transition}
                  sx={{ backgroundColor: "#f7f4cd" }}
                >
                  <AppBar sx={{ position: 'relative', backgroundColor: "#2d2859" }}>
                    <Toolbar>
                      <IconButton
                        edge="start"
                        color="inherit"
                        // onClick={handleClose}
                        aria-label="close"
                      >
                        <SearchIcon />
                      </IconButton>
                      <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Search
                      </Typography>
                      <Button autoFocus color="inherit" onClick={handleClose}>
                        Close
                      </Button>
                    </Toolbar>
                  </AppBar>

                  {Array.isArray(searchdata) && searchdata.length > 0 ? (
                    <Grid mt={2} container spacing={2}>
                      {searchdata.map((datas) => (
                        <Grid onClick={() => handleCategoryDetails(datas.id)} item xs={12} sm={6} key={datas._id}> {/* Use a unique key */}
                          <Card
                            sx={{
                              backgroundColor: '#ffffe0',
                              borderRadius: '15px',
                              padding: '6px',
                              maxWidth: '100%',
                              marginBottom: '16px'
                            }}
                          >
                            <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
                              {/* Images */}
                              <Grid container direction="row" justifyContent="space-around" alignItems="center">
                                {Array.isArray(datas.addimages) && datas.addimages.length > 0 ? (
                                  datas.addimages.map((image, imgIndex) => (
                                    <Grid item key={imgIndex}>
                                      <img
                                        src={image}
                                        alt={`banner-${imgIndex}`}
                                        style={{ width: '100%', height: 'auto', maxWidth: '150px', maxHeight: '150px' }}
                                      />
                                    </Grid>
                                  ))
                                ) : (
                                  <Grid item>
                                    <img
                                      src="/path/to/fallback-image.jpg" // Fallback image URL
                                      alt="fallback"
                                      style={{ width: '100%', height: 'auto', maxWidth: '150px', maxHeight: '150px' }}
                                    />
                                  </Grid>
                                )}
                              </Grid>

                              {/* Service Information */}
                              <Grid>
                                <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '16px' }}>
                                  <CardContent sx={{ flex: '1 0 auto', paddingBottom: '8px' }}>
                                    <Typography component="div" variant="h6">
                                      {datas.servicename}
                                    </Typography>

                                    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
                                      <CheckCircle color="success" sx={{ marginRight: '4px' }} />
                                      <Typography variant="body2" color="textSecondary">
                                        Confirmed
                                      </Typography>
                                      <IconButton color="default" size="small">
                                        <FavoriteBorder />
                                      </IconButton>
                                    </Box>

                                    <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
                                      <LocationOn color="error" />
                                      <Typography variant="body2" color="textSecondary">
                                        {datas.addressline1}, {datas.addressline2}, {datas.city}, {datas.pincode}
                                      </Typography>
                                    </Box>

                                    <Button
                                      variant="outlined"
                                      startIcon={<Phone />}
                                      sx={{
                                        marginTop: '8px',
                                        borderColor: 'black',
                                        color: 'black',
                                      }}
                                    >
                                      {datas.number}
                                    </Button>
                                  </CardContent>
                                </Box>
                              </Grid>
                            </Grid>
                          </Card>
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <Typography variant="body1" color="textSecondary" mt={2}>
                      No Results Found
                    </Typography>
                  )}

                </Dialog>
                <Dialog
                  fullScreen
                  open={open}
                  onClose={handleCodeClose}
                  TransitionComponent={Transition}
                >
                  <AppBar sx={{ position: 'relative', backgroundColor: "#f7f4cd" }}>
                    <Toolbar >
                      <Grid container direction='row' justifyContent="space-evenly" alignItems="center">
                        <img
                          src={Logo}
                          height={"80px"}
                          width="80px"
                          edge="start"
                          color="inherit"
                          onClick={handleCodeClose}
                          aria-label="close"
                        />
                        <Grid item xs={8} container direction='row' justifyContent="space-evenly" alignItems="center" sx={{ width: "100%", height: "60px", borderRadius: "12px", backgroundColor: "#2d2859" }}   >
                          {/* <Grid item >
  {Array.isArray(pincode) && pincode.map((option, index) => (
    <Typography sx={{color:"#fff"}} key={index} variant="body1">
      {option.State}
    </Typography>
  ))}
</Grid>
                   
<Grid item >
  {Array.isArray(pincode) && pincode.map((option, index) => (
    <Typography sx={{color:"#fff"}} key={index} variant="body1">
      {option.District}
    </Typography>
  ))}
</Grid>
<Grid item >
  {Array.isArray(pincode) && pincode.map((option, index) => (
    <Typography sx={{color:"#fff"}} key={index} variant="body1">
      {option.Region}
    </Typography>
  ))}
</Grid> */}
                          <Grid item xs={8} container direction="row" justifyContent="space-evenly" alignItems="center"  >
                            {selectedArea ? (
                              <>
                                <Typography sx={{ color: "#fff" }} variant="body1">
                                  State: {selectedArea.State || "State"}
                                </Typography>
                                <Typography sx={{ color: "#fff" }} variant="body1">
                                  District: {selectedArea.District}
                                </Typography>
                                <Typography sx={{ color: "#fff" }} variant="body1">
                                  Region: {selectedArea.Block}
                                </Typography>
                              </>
                            ) : (
                              <>
                                <Typography sx={{ color: "#fff" }} variant="body1">
                                  State
                                </Typography>
                                <Typography sx={{ color: "#fff" }} variant="body1">
                                  District
                                </Typography>
                                <Typography sx={{ color: "#fff" }} variant="body1">
                                  Region
                                </Typography>
                              </>
                            )}

                          </Grid>

                          <Grid item className="dropdown">
                            <Paper>
                              <Autocomplete
                                disablePortal
                                options={Array.isArray(pincode) ? pincode.map(option => option.Name) : []}
                                sx={{
                                  width: 200,
                                  '& .MuiInputBase-root': {
                                    color: '#2d2859', // Text color
                                    '& .MuiOutlinedInput-notchedOutline': {
                                      border: 'none', // Border color
                                    },
                                  },
                                  '& .MuiInputLabel-root': {
                                    color: '#2d2859', // Label color
                                  },
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    placeholder="Select your Area"
                                    sx={{
                                      '& input': {
                                        color: '#2d2859', // Input text color
                                      },
                                      '& .MuiOutlinedInput-notchedOutline': {
                                        border: 'none', // Border color
                                      },
                                      '& .MuiInputLabel-root': {
                                        color: '#2d2859', // Label color
                                      },
                                    }}
                                  />
                                )}
                                onChange={handleAreaChange} // Handle selection change
                              />
                            </Paper>
                          </Grid>
                        </Grid>
                        <Button sx={{ color: "#000" }} onClick={handleCodeClose}>
                          Close
                        </Button>
                      </Grid>
                    </Toolbar>
                  </AppBar>
                  <Container maxWidth="1200px">
                    {Array.isArray(filteredData) && filteredData.length > 0 ? (
                      <Grid mt={2} container direction="row" justifyContent="space evenly" alignItems="center" spacing={2}>
                        {filteredData.map((datas) => (
                          <Grid onClick={() => handleCategoryDetails(datas.id)} item xs={12} sm={6} key={datas._id}>
                            <Card
                              sx={{
                                backgroundColor: '#ffffe0',
                                borderRadius: '15px',
                                padding: '6px',
                                maxWidth: '100%',
                                marginBottom: '16px'
                              }}

                            >
                              <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start">
                                <Grid xs={12} container direction="row" justifyContent="space-around" alignItems="center">
                                  {Array.isArray(datas.addimages) && datas.addimages.length > 0 ? (
                                    datas.addimages.map((image, imgIndex) => (
                                      <Grid item key={imgIndex}>
                                        <img
                                          src={image}
                                          alt={`banner-${imgIndex}`}
                                          style={{ width: '100%', height: 'auto', maxWidth: '90px', maxHeight: '90px' }}
                                        />
                                      </Grid>
                                    ))
                                  ) : (
                                    <Grid item>
                                      <img
                                        src="/path/to/fallback-image.jpg"
                                        alt="fallback"
                                        style={{ width: '100%', height: 'auto', maxWidth: '150px', maxHeight: '150px' }}
                                      />
                                    </Grid>
                                  )}
                                </Grid>

                                <Grid>
                                  <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '16px' }}>
                                    <CardContent sx={{ flex: '1 0 auto', paddingBottom: '8px' }}>
                                      <Typography component="div" variant="h6">
                                        {datas.servicename}
                                      </Typography>

                                      <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
                                        <CheckCircle color="success" sx={{ marginRight: '4px' }} />
                                        <Typography variant="body2" color="textSecondary">
                                          Confirmed
                                        </Typography>
                                        <IconButton color="default" size="small">
                                          <FavoriteBorder />
                                        </IconButton>
                                      </Box>

                                      <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '8px' }}>
                                        <LocationOn color="error" />
                                        <Typography variant="body2" color="textSecondary">
                                          {datas.addressline1}, {datas.addressline2}, {datas.area}, {datas.city}, {datas.pincode}
                                        </Typography>
                                      </Box>

                                      <Button
                                        variant="outlined"
                                        startIcon={<Phone />}
                                        sx={{
                                          marginTop: '8px',
                                          borderColor: 'black',
                                          color: 'black',
                                        }}
                                      >
                                        {datas.number}
                                      </Button>
                                    </CardContent>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Card>
                          </Grid>
                        ))}
                      </Grid>
                    ) : (
                      <Typography variant="body1" color="textSecondary" mt={2}>
                        No Results Found
                      </Typography>
                    )}
                  </Container>
                </Dialog>
              </Grid>
            </nav>
          </Grid>


        </Container>
      )}

    </>




  );
}

export default NavBar;
