import React, { useEffect, useState } from 'react';
import { Card, Container, CardMedia, Typography, Grid, Button, Box, Rating } from '@mui/material';
import './style.css';
import NavBar from '../navbar';
import Footer from '../footer';
import Spa3 from '../../assets/img/spa3.webp';
import Cookies from 'js-cookie';
import { jwtDecode } from "jwt-decode";

function Wishlist() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 425);
    const [data, setData] = useState([]);
    const [token, setToken] = useState(null);

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 425);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Check if the token exists
    useEffect(() => {
        const storedToken = Cookies.get('token');
        if (storedToken) {
            try {
                const decodedToken = jwtDecode(storedToken);
                setToken(decodedToken);
            } catch (error) {
                console.error("Invalid token:", error);
                setToken(null); // Set token to null if it's invalid
            }
        }
    }, []);

    useEffect(() => {
        if (token) {
            getWishlist();
        }
    }, [token]);

    const getWishlist = () => {
        try {
            const requestOptions = {
                method: "GET",
                redirect: "follow"
            };

            fetch(`/api/wishlistdata?id=${token?.user.id}`, requestOptions)
                .then(async (response) => {
                    if (response.status === 200 || response.status === 400) {
                        return { status_code: response.status, data: await response.json() };
                    } else {
                        return { status_code: response.status, data: { msg: 'Unexpected Error' } };
                    }
                })
                .then((result) => {
                    setData(result.data.data[0]?.result[0]?.service || []);
                    console.log(result.data.data[0], "Service Data");
                })
                .catch(error => console.log('error', error));
        } catch (error) {
            console.error('Error fetching wishlist:', error);
        }
    };

    return (
        <>
            {isMobile ? (
                <>
                    <NavBar />

                    <Container>
                        <Grid item xs={12} mt={3} container direction="row" justifyContent="space-between" alignItems="center">
                            <Grid item xs={6} container direction="row" justifyContent="center" alignItems="flex-end">
                                <Typography id="header-mob">Favorites</Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ mt: 0 }}>
                                <hr style={{ margin: '0', padding: '0', border: 'none', borderBottom: '1px solid #000' }} />
                            </Grid>
                        </Grid>
                    </Container>

                    <Container>
                        <Grid item xs={12} md={12} container direction="row" justifyContent="space-between" alignItems="center">
                            <Grid item xs={4}>
                                <CardMedia
                                    component="img"
                                    className="product-image-mob"
                                    image={Spa3}
                                    alt="Product-Image"
                                />
                            </Grid>
                            <Grid item xs={3} container
                                direction="row"
                                justifyContent="center"
                                alignItems="center">
                                <Typography id="text-mob">Forest Face Care</Typography>
                                <Typography className='mt-2' id="text-mob">Availability: <span className="product-availability">Instock</span></Typography>
                                <Box className="rating-box">
                                    <Typography id="text-mob">Rating:</Typography>
                                    <Rating id="rate-mob" name="rating" value={4} readOnly />
                                </Box>
                            </Grid>
                            <Grid xs={3}>
                                <Box className="buttons-box">
                                    <Button variant="contained" className="add-cart-button-mob">Add Cart</Button>
                                    <Button variant="contained" className="add-cart-button-mob">Delete</Button>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>

                    <Footer />
                </>
            ) : (
                <>
                    <NavBar />
                    <Container>
                        <Grid item xs={12} mt={5} container direction="row" justifyContent="space-between" alignItems="center">
                            <Grid item xs={6} mt={5} container direction="row" justifyContent="flex-start" alignItems="center">
                                <Typography id="header">My Favorites</Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ mt: 0 }}>
                                <hr style={{ margin: '0', padding: '0', border: 'none', borderBottom: '1px solid #000' }} />
                            </Grid>
                        </Grid>
                    </Container>

                    <Container sx={{ marginTop: "20px" }}>
                        {Array.isArray(data) && data.length > 0 ? (
                            <Grid mb={5} container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                                {data.map((datas, index) => (
                                    <Grid item xs={10} key={index}>
                                        <Card sx={{ backgroundColor: "#f7f4cd", border: "1px solid black", padding: 2 }}>
                                            <Grid container direction="row" justifyContent="space-evenly" alignItems="center">
                                                <Grid item xs={4}>
                                                    <CardMedia
                                                        component="img"
                                                        className="product-image"
                                                        src={datas.addimages[0]}
                                                        alt="Product Image"
                                                    />
                                                </Grid>
                                                <Grid item xs={4} container direction="column" justifyContent="space-evenly" alignItems="flex-start">
                                                    <Typography id="text-head">{datas.servicename}</Typography>
                                                    <Box className="mt-1">
                                                        <Typography id="text">Address:</Typography>
                                                        <Typography>{`${datas.addressline1}, ${datas.addressline2}, ${datas.area}, ${datas.city}, ${datas.state}`}</Typography>
                                                    </Box>
                                                    <Box className="rating-box">
                                                        <Typography id="text">Rating:</Typography>
                                                        <Rating id="rate" name="rating" value={4} readOnly />
                                                    </Box>
                                                </Grid>
                                                <Grid item xs={4} container justifyContent="center" alignItems="center">
                                                    <Button variant="contained" className="add-cart-button">Delete</Button>
                                                </Grid>
                                            </Grid>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        ) : (
                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                <Card sx={{ backgroundColor: "#f7f4cd", border: "1px solid black", padding: 2, width: "500px" }}>
                                    <Typography variant="h6" align="center">No items in your wishlist</Typography>
                                </Card>
                            </Grid>
                        )}
                    </Container>

                    <Footer />
                </>
            )}
        </>
    );
}

export default Wishlist;
