import React, { useState, useEffect } from 'react';
import {Button,Grid,Card,Typography,TextField,Link,} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import PersonIcon from '@mui/icons-material/Person';
import StoreIcon from '@mui/icons-material/Store';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

import Cookies from 'js-cookie';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#ffffe0',
    ...theme.typography.body2,
    border:"1px solid #2d2859  ",
    padding: theme.spacing(1),
    textAlign: 'center',
    color: "#000",
    width:"100%",
   
  }));

export default function LoginDialog() {

    const [number,setNumber] = useState('')
    const [password,setPassword] = useState('')
    const [registeropen, setRegisteropen] = React.useState(false);
    const navigate = useNavigate();

    const navigateuser = () => {
        navigate("/registeruser");
      };
    
      const navigatebusiness = () => {
        navigate("/registerbusiness");
      };
    
      const navigatefreelancer = () => {
        navigate("/registerfreelancer");
      };

      const handleRegisterOpen = () => {
        setRegisteropen(true);
      };
    
      const handleRegisterClose = () => {
        setRegisteropen(false);
      };
    
  


    const handlesignin = () =>{
        try{
          const myHeaders = new Headers();
          myHeaders.append("Content-Type", "application/json");
          
          const raw = JSON.stringify({
            "phonenumber": number,
            "password": password
          });
          
          const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
          };
          
          fetch("/api/login", requestOptions)
          .then(async (response) => {
            if (response.status === 200 || response.status === 400) {
                return { status_code: response.status, data: await response.json() };
            } else {
                return { status_code: response.status, data: { msg: 'Unexpected Error' } };
            }
        })
        .then((result) => {
            if (result.status_code === 200) {
                console.log(result.data.token, " Sign in Token stored");
                const token = result.data.token;
                Cookies.set('token', token, { expires: 7, secure: true });
                window.location.reload()
                // setOpen(true);
                // setColor('success');
                // setMsg(result.data.msg || "Register Success");
                // setTimeout(() => {
                //   navigate('/');
                // }, "1000");
    
            } else if (result.status_code === 400) {
                // setOpen(true);
                // setColor('error');
                // setMsg(result.data.msg);
    
            } else if (result.status_code === 401) {
                // Handle unauthorized access
                // setOpen(true);
                // setColor('error');
                // setMsg("Unauthorized access");
            }
        })
          .catch(error => console.log('error', error));
          
            }catch(error){
              console.error(error)
            //   setOpen(true);
            //   setColor('error');
            //   setMsg(error.response.data.message||'An Error Occured');
            }
      }
      

  return (
    <React.Fragment>
      <Grid>
        <Grid container direction="column" justifyContent="space-evenly" alignItems="center" >
              <Grid className="mt-2">
        <TextField
                  placeholder='Enter your number'
                  className='mt-4'
                  value={number}
                  onChange={(e)=>{
                    setNumber(e.target.value)
                  }} 
                  
                  sx={{
                    '& .MuiInputBase-root': {
                      borderRadius: '5px',
                      width: "350px",
                      height: "50px",
                      color: "#fff",
                    },
                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#fff',
                    },
                    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#fff',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#fff',
                    },
                  }}
                />
              </Grid>
              <Grid className="mb-3 mt-4">
        <TextField
                  placeholder='Enter your password'
                  
                  value={password}
                  onChange={(e)=>{
                    setPassword(e.target.value)
                  }} 
                  inputProps={{color:"#fff" }} // Ensure the maximum length is 10
                  sx={{
                    '& .MuiInputBase-root': {
                      borderRadius: '5px',
                      width: "350px",
                      height: "50px",
                      color: "#fff",
                    },
                    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#fff',
                    },
                    '& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#fff',
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                      borderColor: '#fff',
                    },
                  }}
                />
              </Grid>
              <Button
                  variant="contained"
                  type="submit"
                  className=" mt-2"
          onClick={()=>{
            // if(!number){
            //   setOpen(true);
            //   setColor('error');
            //   setMsg('Phone Number required');
            //   return
            // }
            // if(number.length!==10){
            //   setOpen(true);
            //   setColor('error');
            //   setMsg('Phone Number have 10 number');
            //   return
            // }
            // if(!password){
            //   setOpen(true);
            //   setColor('error');
            //   setMsg('Password required');
            //   return
            // }
            
            handlesignin()
          }}
          sx={{backgroundColor:"#f7f4cd", color:"#000","&:hover": {backgroundColor: "#f7f4cd"}}}
              >
                  Log In
              </Button>
              <Typography sx={{color:"#fff",fontSize:"10px",cursor:"pointer"}} className="mt-2">
                  Forget your password?
              </Typography>
              <Typography className='mt-3' sx={{fontSize: "10px",fontWeight:"bold",color:"#fff"}}>
                    Create a new account,  <Link onClick={handleRegisterOpen} sx={{color:"#C052E3",textDecoration:"none",cursor:"pointer"}}> register here</Link>
                  </Typography>
          </Grid>
          <Dialog
          open={registeropen}
          onClose={handleRegisterClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle id="responsive-dialog-title" sx={{backgroundColor:"#2d2859"}}>
            <Typography sx={{fontSize:"30px",color:"#fff"}}>Register Here !</Typography>
            <Typography sx={{fontSize:"10px",color:"#fff"}}>click your role</Typography>
          </DialogTitle>
          <DialogContent sx={{width:"600px",backgroundColor:"#2d2859"}} >
            <DialogContentText>
             <Grid container direction="column" justifyContent="center" alignItems="center">
             <Item onClick={navigateuser} className='mb-3 mt-4'>
              <Typography  sx={{fontSize:"22px"}}><PersonIcon sx={{fontSize:"25px"}}/> User Registration </Typography>
             </Item>
             <Item onClick={navigatebusiness} className='mb-3'>
              <Typography  sx={{fontSize:"22px"}}><StoreIcon sx={{fontSize:"25px"}}/> Business Registration </Typography>
             </Item>
             <Item onClick={navigatefreelancer} className='mb-3'>
              <Typography   sx={{fontSize:"22px"}}><PersonOutlineIcon sx={{fontSize:"25px"}}/> Freelancer Registration </Typography>
             </Item>
             </Grid>
            </DialogContentText>
          </DialogContent>
          <DialogActions sx={{backgroundColor:"#2d2859"}}>
            {/* <Button autoFocus onClick={handleRegisterClose}>
              Disagree
            </Button> */}
            <Button sx={{color:"#fff"}} onClick={handleRegisterClose} >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </React.Fragment>
  );
}