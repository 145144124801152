import React, { useState, useEffect } from 'react';
import './style.css';
import { Container, Grid, Typography } from '@mui/material';
import qrcode from '../../assets/img/qrcode.png';
import {  Link } from 'react-router-dom';
import Home from '../../assets/qik call mobile view icons/home-mob.png'
import Business from '../../assets/qik call mobile view icons/business-mob.png'
import User from '../../assets/qik call mobile view icons/user-mob.png'

const Footer = () => {
    
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 425);

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 425);
    };

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);



    return (
        <>
            {isMobile ? (
                <>
                    <Container>
                    <Grid mb={3} mt={3} spacing={2} container id="grid-container-mob">
      <Grid item xs={9} container id="grid-item-mob">
        <Grid xs={12} container id="link-container-mob">
          <Grid xs={6} item container 
  direction="column"
  justifyContent="space-evenly"
  alignItems="center">
            <Typography component={Link} to="/aboutus" id="typography-link-mob">
              About Us
            </Typography>
            <Typography component={Link} to=" /termsandcondition" id="typography-link-mob">
               Terms & Conditions
            </Typography>
          </Grid>
          <Grid xs={6} item container 
  direction="column"
  justifyContent="space-evenly"
  alignItems="center">
            <Typography component={Link} to="/contactus" id="typography-link-mob">
            Contact Us
            </Typography>
            <Typography component={Link} to="/privacy" id="typography-link-mob">
              Privacy Policy
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={3} container direction="row" justifyContent="space-evenly" alignItems="center">
        <img src={qrcode} alt="QR Code" id="qr-code-mob" />
      </Grid>
      {/* <Grid container xs={12} direction="row" justifyContent="center" alignItems="center" id="footer-copyright">
        Copyright@2024
      </Grid> */}
    </Grid>
                    </Container>
                    <Container>
                    <Grid mt={3} mb={2} className="grid-mob" item xs={12} container direction="row" justifyContent="space-around" alignItems="center">
      <Grid>
      <Link to="/">
    <img src={Home} alt="" className="img-home-mob" />
  </Link>
      </Grid>
      <Grid>
      <Link to="/business">
        <img src={Business} alt="" className="img-business-mob" />
      </Link>  
      </Grid>
      <Grid>
      <Link to="/userprofile">
        <img src={User} alt="" className="img-user-mob" />
        </Link>
      </Grid>
    </Grid>
                    </Container>
                </>
            ) : (
                <>
                    <Container>
                        <Grid container id="footer">
                            <Grid item xs={12} md={8} container direction="row" justifyContent="flex-end" alignItems="center" id="footer-links">
                            <Typography id="footer-text" component={Link} to="/aboutus">About Us</Typography>
<Typography id="footer-text" component={Link} to="/contactus">Contact Us</Typography>
<Typography id="footer-text" component={Link} to="/termsandcondition">Terms & Conditions</Typography>
<Typography id="footer-text" component={Link} to="/privacy">Privacy Policy</Typography>
<Typography id="footer-text" component={Link} to="/emergency">Emergency Services</Typography>

                            </Grid>
                            <Grid item xs={12} md={4} container direction="row" justifyContent="center" alignItems="center" className="footer-qr">
                                <img src={qrcode} alt="QR Code" sx={{width:"200px",height:"200px"}} />
                            </Grid>
                            <Grid container item xs={12} direction="row" justifyContent="center" alignItems="center" className="footer-copyright">
                                <Typography id='footer-text' >Copyright@2024</Typography>
                            </Grid>
                        </Grid>
                    </Container>
                   
                </>
            )}
        </>
    );
};

export default Footer;
