import React, { useEffect, useState } from 'react';
import {
  Box, TextField, Typography, Select, MenuItem, FormControl, InputLabel, Grid, Container, Button, Card, CardMedia, IconButton, Snackbar, Alert
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import Chip from '@mui/material/Chip';
import NavBar from '../navbar';
import Footer from '../footer';
import { useNavigate } from 'react-router-dom';




const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];

function getStyles(name, personName, theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

function ServiceProfileForm() {
  const [color, setColor] = useState('');
  const [open, setOpen] = useState(false);
  const [msg, setMsg] = useState('');
  const [name, setName] = useState('');
  const [number, setNumber] = useState('');
  const [type, setType] = useState([]);
  const [link, setLink] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
const [addressLine2, setAddressLine2] = useState('');
const [area, setArea] = useState('');
const [city, setCity] = useState('');
const [pincode, setPincode] = useState('');
const [state, setState] = useState('');
  const [getData,setGetData] = useState('')
  const [images, setImages] = useState(['', '', '', '']);
  // const navigate = useNavigate();

  console.log(getData,"+++++++++++")

  const handleService = async () => {
    try {
        
        const uploadedImageUrl = await uploadimages();

        // Prepare the request data
        const raw = JSON.stringify({
            "servicename": name,
            "number": number,
            "servicetype": type,
            "addressline1": addressLine1,
  "addressline2": addressLine2,
  "area": area,
  "city": city,
  "pincode": pincode,
  "state": state,
            "websitelink": link,
            "addimages": uploadedImageUrl 
        });

        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        // Make the service request
        const response = await fetch("/api/service", requestOptions);
        const result = {
            status_code: response.status,
            data: await response.json()
        };
        console.log(result.data,"++++++++++")

        // Handle the response
        if (result.status_code === 200) {
            setOpen(true);
            setColor('success');
            
            setMsg(result.data.msg );
        } else if (result.status_code === 400) {
            setOpen(true);
            setColor('error');
            setMsg(result.data.msg );
        }
    } catch (error) {
        setOpen(true);
        setColor('error');
        setMsg('An unexpected error occurred');
        console.error(error);
    }
};

useEffect(()=>{
  GetCategory()
},[])

const GetCategory= ()=>{
try{
  const requestOptions = {
    method: "GET",
    redirect: "follow"
  };
  
  fetch("/api/categorylist", requestOptions)
  .then(async (response) => {
    if (response.status === 200 || response.status === 400) {
      return { status_code: response.status, data: await response.json() };
    } else {
      return { status_code: response.status, data: { msg: 'Unexpected Error' } };
    }
  })
  .then((result) => {
    setGetData(result.data.data)
    console.log(result.data.data,"good")
   })
  .catch(error => console.log('error', error));
}catch(error){

}

}

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const[files,setFiles] = useState('')

  const handleImageChange = (index, event) => {
    const file = event.target.files[0];
    if (file) {
      if (file.type.startsWith('image/')) {
        const newImages = [...images];
        newImages[index] = URL.createObjectURL(file); // Show the image preview
        setImages(newImages);
        
        // Save the file object in state for later uploading
        const newFiles = [...files];
        newFiles[index] = file;
        setFiles(newFiles);
      } else {
        alert('Please select a valid image file.');
      }
    }
  };

  

  const uploadimages = async () => {
    try {
      const uploadedImageUrls = await Promise.all(files.map(async (file) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('upload_preset', 'ae1kvvqp'); // Replace with your Cloudinary upload preset
  
        const response = await fetch('https://api.cloudinary.com/v1_1/qikcall/image/upload', {
          method: 'POST',
          body: formData,
        });
  
        const data = await response.json();
  
        if (data.secure_url) {
          console.log(data.secure_url,"data")
          return data.secure_url;
        } else {
          throw new Error('Failed to upload image.');
        }
      }));
  
      return uploadedImageUrls;
    } catch (error) {
      console.error('Error uploading images:', error);
      throw error;
    }
  };

  const theme = useTheme();
  const [personName, setPersonName] = React.useState([]);

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setType(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  return (
    <>
      <NavBar />
      <Container sx={{ marginBottom: "15px" }}>
        <Box
          sx={{
            backgroundColor: '#f7f4cd', // light yellow background
            height: 'auto',
            width: "100%",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          mt={3}
          mb={5}
        >
          <Box
            sx={{
              backgroundColor: '#f7f4cd', // slightly darker yellow for the form
              padding: '2rem',
              borderRadius: '8px',
              boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              border: '1px solid #333366', // border color matching the text color
              maxWidth: '800px',
              width: '100%',
            }}
          >
            <Typography
              variant="h4"
              align="center"
              sx={{ marginBottom: '2rem', color: '#333366' }}
            >
              Service Profile
            </Typography>

            <FormControl fullWidth margin="normal">
              <TextField
                id="service-name"
                variant="outlined"
                value={name}
                onChange={(e) => setName(e.target.value)}
                label="Service Name"
                InputProps={{
                  style: {
                    color: '#000',
                    borderColor: '#333366'
                  },
                }}
              />
            </FormControl>

            <FormControl fullWidth margin="normal">
            {/* <InputLabel id="demo-multiple-chip-number">Mobile Number</InputLabel> */}
              <TextField
              // labelId="demo-multiple-chip-number"
                id="service-number"
                variant="outlined"
                value={number}
                label="Mobile Number"
                onChange={(e) => setNumber(e.target.value)}
                
                InputProps={{
                  style: {
                    color: '#000',
                    borderColor: '#333366',
                    
                  },
                }}
              />
            </FormControl>

            <FormControl fullWidth margin="normal" >
        <InputLabel id="demo-multiple-chip-label">Category</InputLabel>
        <Select
          labelId="demo-multiple-chip-label"
          id="demo-multiple-chip"
          multiple
          value={type}
          onChange={handleChange}
          input={<OutlinedInput id="select-multiple-chip" label="Category" />}
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {Array.isArray(getData)&& getData.map((category) => (
            <MenuItem
              key={category._id}
              value={category.categoryname}
              style={getStyles(name, type, theme)}
            >
              {category.categoryname}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Typography  sx={{ fontSize: "25px", fontWeight: "bold", marginBottom: 2,marginTop:2 }}>
                Address:
              </Typography>
              <TextField
                label="Door No"
                variant="outlined"
                value={addressLine1}
                fullWidth
                sx={{ marginBottom: 2 }}
                onChange={(e)=>{
                  setAddressLine1(e.target.value)
                }}

              />
              <TextField
                label="Street/Colony"
                variant="outlined"
                fullWidth
                value={addressLine2}
                sx={{ marginBottom: 2 }}
                onChange={(e)=>{
                  setAddressLine2(e.target.value)
                }}
              />
              <TextField
                label="Area"
                variant="outlined"
                fullWidth
                value={area}
                sx={{ marginBottom: 2 }}
                onChange={(e)=>{
                  setArea(e.target.value)
                }}
              />
              <TextField
                label="City"
                variant="outlined"
                fullWidth
                value={city}
                sx={{ marginBottom: 2 }}
                onChange={(e)=>{
                  setCity(e.target.value)
                }}
              />
              <TextField
                label="Pincode"
                variant="outlined"
                fullWidth
                value={pincode}
                sx={{ marginBottom: 2 }}
                onChange={(e)=>{
                  setPincode(e.target.value)
                }}
              />
              <TextField
                label="State"
                variant="outlined"
                fullWidth
                value={state}
                sx={{ marginBottom: 2 }}
                onChange={(e)=>{
                  setState(e.target.value)
                }}
              />

            <FormControl fullWidth margin="normal">
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
                <Typography variant="body2" sx={{ color: '#333366' }}>*Optional</Typography>
              </Box>
              <TextField
                id="website-link"
                variant="outlined"
                value={link}
                onChange={(e) => setLink(e.target.value)}
               label="Website link"
                InputProps={{
                  style: {
                    color: '#333366',
                    borderColor: '#333366'
                  },
                }}
              />
            </FormControl>

            <Typography
              variant="h6"
              align="center"
              sx={{ marginTop: '2rem', marginBottom: '1rem', color: '#333366' }}
            >
              Add Images
            </Typography>

            <Grid container spacing={2} justifyContent="center" alignItems="center">
              {/* First Row */}
              <Grid container item xs={12} spacing={2} justifyContent="center" alignItems="center">
                {images.slice(0, 2).map((image, index) => (
                  <Grid item xs={12} sm={5} md={5} key={index}>
                    <input
                      accept="image/*"
                      style={{ display: 'none' }}
                      id={`file-input-${index}`}
                      type="file"
                      onChange={(event) => handleImageChange(index, event)}
                    />
                    <label htmlFor={`file-input-${index}`}>
                      <IconButton component="span">
                        <Card sx={{ width: '300px' }}>
                          <CardMedia
                            component="img"
                            height="140"
                            image={image}
                            alt={`Image ${index + 1}`}
                            sx={{ cursor: 'pointer', width: '100%' }}
                          />
                        </Card>
                      </IconButton>
                    </label>
                  </Grid>
                ))}
              </Grid>

              {/* Second Row */}
              <Grid container item xs={12} spacing={2} justifyContent="center" alignItems="center">
                {images.slice(2, 4).map((image, index) => (
                  <Grid item xs={12} sm={5} md={5} key={index + 2}>
                    <input
                      accept="image/*"
                      style={{ display: 'none' }}
                      id={`file-input-${index + 2}`}
                      type="file"
                      onChange={(event) => handleImageChange(index + 2, event)}
                    />
                    <label htmlFor={`file-input-${index + 2}`}>
                      <IconButton component="span">
                        <Card sx={{ width: '300px' }}>
                          <CardMedia
                            component="img"
                            height="140"
                            image={image}
                            alt={`Image ${index + 3}`}
                            sx={{ cursor: 'pointer', width: '100%' }}
                          />
                        </Card>
                      </IconButton>
                    </label>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Box>
        </Box>
        <Grid container justifyContent="center" mt={2}>
          <Button
            variant="contained"
            onClick={()=>{
              if(!name){
                setOpen(true);
                setColor('error');
                setMsg('Address required');
                return
              }
              if(!number){
                setOpen(true);
                setColor('error');
                setMsg('Address required');
                return
              }
              if(number.length!==10){
                setOpen(true);
                setColor('error');
                setMsg('Phone Number have 10 number');
                return
              }
              if(!type){
                setOpen(true);
                setColor('error');
                setMsg('Address required');
                return
              }
              if(!images){
                setOpen(true);
                setColor('error');
                setMsg('Address required');
                return
              }
              if(!addressLine1){
                setOpen(true);
                setColor('error');
                setMsg('Address required');
                return
              }
              if(!addressLine2){
                setOpen(true);
                setColor('error');
                setMsg('Address required');
                return
              }
              if(!area){
                setOpen(true);
                setColor('error');
                setMsg('Address required');
                return
              }
              if(!city){
                setOpen(true);
                setColor('error');
                setMsg('Address required');
                return
              }
              if(!state){
                setOpen(true);
                setColor('error');
                setMsg('Address required');
                return
              }
              if(!pincode){
                setOpen(true);
                setColor('error');
                setMsg('Address required');
                return
              }
              handleService()
            
            }}
            sx={{ borderRadius: 50, fontSize: "15px", backgroundColor: "#282866" }}
          >
            Add Services
          </Button>
        </Grid>
        <Snackbar open={open} autoHideDuration={4000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity={color}
            variant="filled"
            sx={{ width: '100%' }}
          >
            {msg}
          </Alert>
        </Snackbar>
      </Container>
      <Footer />
    </>
  );
}

export default ServiceProfileForm;
